@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  * {
    scrollbar-width: 10px;
    scrollbar-color: #95c5b1 #ffffff;
    scrollbar-arrow-color: #d2ffe9;
  }
}


body {
    font-family:  "Times New Roman", Times, serif;
}

input{
  font-family:Arial, Helvetica, sans-serif
}

h1 {
  @apply text-4xl font-bold; 
}

h2 {
  @apply text-3xl font-semibold; 
}

h3 {
  @apply text-2xl font-semibold; 
}

h4 {
  @apply text-xl font-medium; 
}

h5 {
  @apply text-lg font-medium; 
}

h6 {
  @apply text-base font-medium; 
}


@layer components {
  .golden{
    background-image:linear-gradient(to right, #AA7A38, #E2DD99, #B28947, #E1A84F);
  }
  .golden-txt {
    background-image:linear-gradient(to right, #AA7A38, #E2DD99, #B28947, #E1A84F);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.button-linear-text {
  background: linear-gradient(to left, #B68340,  #E2DB97);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.button-dashboard-tab-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15399999916553497px;
  text-align: left;
  color : #828282
}